import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const full_routes: Routes = [
  {
    path: 'publicacoes',
    loadChildren: './pages/full-pages/publicacao/publicacao.module#PublicacaoModule'
  },
  {
    path: 'rascunho',
    loadChildren: './pages/full-pages/rascunho/rascunho.module#RascunhoModule'
  },
  {
    path: 'administracao',
    loadChildren: './pages/full-pages/administracao/administracao.module#AdministracaoModule'
  }
];