import { HttpInterceptor, HttpResponse, HttpErrorResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpEvent } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { NotificacaoService } from '../../components/notificacoes/notificacao.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';

declare var NProgress: any;

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {

    constructor(private injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const notificacao = this.injector.get(NotificacaoService);
        NProgress.start();

        return next.handle(request)
        .do((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && event.status >= 200 && event.status < 400) {
                if (event.headers.get('X-App-Message')) {
                    notificacao.sucesso(event.headers.get('X-App-Message'))
                }

                setTimeout(function() {
                    NProgress.done();
                }, 5000);
            }
        }, e => {
            if (e instanceof HttpErrorResponse) {
                if(!!e.error.message) {
                    if (e.status > 404 && e.status < 500) {
                        if(!e.error.message.includes('<li>')) {
                            notificacao.alerta(e.error.message);
                        }
                    } else if(e.status > 500) {
                        notificacao.erro(e.error.message);
                    }
                }
            }

            setTimeout(function() {
                NProgress.done();
              }, 5000);
        });
    }

}
