import { Pipe, PipeTransform, NgZone, ChangeDetectorRef } from "@angular/core";

@Pipe({
	name:'leftZeroes',
	pure:false
})
export class LeftZeroesPipe implements PipeTransform {
    
    constructor() {}
    
	transform(value:number) {
		const numZeros = 9;
		const n = Math.abs(value);
    	const zeros = Math.max(0, numZeros - Math.floor(n).toString().length );
    	let zeroString = Math.pow(10,zeros).toString().substr(1);
    	if( value < 0 ) {
        	zeroString = '-' + zeroString;
    	}

    	return zeroString + n;
	}
	
}