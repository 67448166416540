import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform  {
  
  constructor() {}

  transform(value:String, termos:string[]) {
    if (!termos) {
      return value;
    }
  
      const regex = new RegExp(`(?<!</?[^>]*|&[^;]*)(${termos.join('|')})`, 'gi');
      const match = value.match(regex);
  
      if (!match) {
        return value;
      }
  
      value = value.replace(regex, function ($replacer) {
         return `<span class='highlight'>${$replacer}</span>`
      });

    return value;
  }
}