import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { InputComponent } from '../components/input/input.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TimeAgoPipe } from '../components/timeAgoPipe/time-ago.pipe';
import { DataTableModule } from '../components/data-table';
import { DateValueAccessor } from '../components/date-value-accessor/date-value-accessor';
import { SafeHtmlPipe } from '../components/safe-html/safe-html.pipe';
import { SimplePagingComponent } from '../components/simple-paging/simple-paging.component';
import { LeftZeroesPipe } from '../components/leftZeroesPipe/left-zeroes.pipe';
import { MateriaComponent } from '../components/materia/materia.component';
import { HighlightPipe } from 'app/components/highlight/highlight.pipe';
import { HasAuthorityDirective } from './directives/has-authority.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    DataTableModule
  ],
  exports: [
    DataTableModule,
    InputComponent,
    SimplePagingComponent,
    TimeAgoPipe,
    LeftZeroesPipe,
    SafeHtmlPipe,
    HighlightPipe,
    DateValueAccessor,
    MateriaComponent,
    HasAuthorityDirective
  ],
  declarations: [
    MateriaComponent,
    SimplePagingComponent,
    InputComponent,
    TimeAgoPipe,
    LeftZeroesPipe,
    SafeHtmlPipe,
    HighlightPipe,
    DateValueAccessor,
    HasAuthorityDirective
  ]
})

export class SharedModule { }
