import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';

import { content_routes } from './shared/routes/content-layout.routes';
import { full_routes } from './shared/routes/full-layout.routes';
import { OabSegurancaGuard } from 'oab-seguranca';


export const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'pages/visualizacao', 
    pathMatch: 'full' 
  },
  { 
    path: '', component: FullLayoutComponent, data: { title: 'full Views' }, 
    children: full_routes, 
    canActivate: [ OabSegurancaGuard ] 
  },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: content_routes },
  { path: '**', redirectTo: '/pages/404' }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: false });
