import { DataTableRow } from './row';
import { DataTableColumn } from './column';


export type RowCallback = (item: any, row: DataTableRow, index: number) => string;

export type CellCallback = (item: any, row: DataTableRow, column: DataTableColumn, index: number) => string;

// export type HeaderCallback = (column: DataTableColumn) => string;

export interface DataTableTranslations {
    indexColumn: string;
    selectColumn: string;
    expandColumn: string;
    paginationLimit: string;
    paginationRange: string;
    paginationShowing: string;
    paginationOf: string;
    paginationRows: string;
}

export var defaultTranslations = <DataTableTranslations>{
    indexColumn: 'index',
    selectColumn: 'select',
    expandColumn: 'expand',
    paginationLimit: 'Limite',
    paginationShowing: 'Exibindo de',
    paginationOf: 'até',
    paginationRows: 'registro(s)'
};


export interface DataTableParams {
    filter: any;
    offset?: number;
    limit?: number;
    sortBy?: string;
    sortAsc?: boolean;
}