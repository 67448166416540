// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const host = ''

export const environment = {
  production: false,
  host: 'http://localhost:4200/',
  api: 'http://localhost:8080',
  seguranca:{
    roles:{
      publicador: '5ba4b1f9-55ff-40c0-83c6-17ea5affbc6a',
      redator: '7719df54-5e86-45fd-ba2a-79cd88327ea0',
      admin: 'f4e3b4be-94ac-4091-a8ae-bb73deb06915'
    }
  }
};
