import { Component, Output, Input, EventEmitter } from "@angular/core";
import { Page } from "./page.type";

declare var $: any;

@Component({
    selector: 'app-simple-paging',
    templateUrl: './simple-paging.component.html',
    styleUrls: ['./simple-paging.component.scss']
  })
export class SimplePagingComponent {

    @Input() page: Page;

    @Output() onPage = new EventEmitter();

    next(page) {
        this.onPage.emit(page);
        this.scrollTop();
    }

    prev(page) {
        this.onPage.emit(page);
        this.scrollTop();
    }

    scrollTop() {
        $(function(){
            $('body, html').animate({'scrollTop': 0}, 500);
        });
    }

}