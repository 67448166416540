import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'app-materia',
  templateUrl: './materia.component.html',
  styleUrls: ['./materia.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MateriaComponent {

  @Input() titulo: string

  @Input() conteudo: any

  @Input() termosPesquisados: String[]

}

