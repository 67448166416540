
import { Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import { OabSegurancaService } from 'oab-seguranca';

/**
 * @howToUse
 * ```
 *     <some-element *hasAuthority="'ROLE_NAME1'">...</some-element>
 *
 *     <some-element *hasAuthority="['ROLE_NAME1', 'ROLE_NAME2']">...</some-element>
 * ```
 */
@Directive({
    selector: '[hasAuthority]'
})
export class HasAuthorityDirective {
    private authorities: string[];

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private oabSeguranca: OabSegurancaService
    ) {
    }

    @Input()
    set hasAuthority (value: string | string[]) {
        this.authorities = typeof value === 'string' ? [value] : value;
        this.updateView();
    }

    private updateView(): void {
        if(this.authorities.length == 0){
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }else {
            for (let i = 0; i < this.authorities.length; i++) {
                console.log(this.authorities[i])
                console.log(this.oabSeguranca.isUserInRole(this.authorities[i]))
                if(this.oabSeguranca.isUserInRole(this.authorities[i])){
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                    return;
                }
            }
        }
    }
}