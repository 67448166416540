import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData, CommonModule } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, NavigationStart, NavigationEnd, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CurrencyMaskModule } from "ng2-currency-mask";
import { OabSegurancaModule } from 'oab-seguranca';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask/src/currency-mask.config";

import { routing } from './app.routing';

import { AppComponent } from './app.component';
import { MenuLeftComponent } from './components/menu-left/menu-left.component';
import { FooterComponent } from './components/footer/footer.component';

import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotificacaoService } from './components/notificacoes/notificacao.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EventService } from './shared/service/event.service';
import { NotificationInterceptor } from './shared/service/notification.interceptor';
import { environment } from 'environments/environment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { SharedModule } from './shared/shared.module';

defineLocale('pt-br', ptBrLocale); 

declare var NProgress: any;

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};

registerLocaleData(localePt, 'pt-BR');

@NgModule({
    declarations: [
        AppComponent,
        MenuLeftComponent,
        FooterComponent,
        FullLayoutComponent,
        ContentLayoutComponent
    ],
    imports: [
        BrowserModule,
        SharedModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        HttpClientModule,
        NgbModule.forRoot(),
        ModalModule.forRoot(),
        NgxMyDatePickerModule.forRoot(),
        routing,
        CurrencyMaskModule,
        OabSegurancaModule.forRoot({ 
          production: environment.production,
          owner: false
        })
    ],
    providers: [
      EventService,
      NotificacaoService,
      { provide: LOCALE_ID, useValue: 'pt-BR' },
      { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
      { provide: HTTP_INTERCEPTORS, useClass: NotificationInterceptor, multi: true  },
    ],
    bootstrap: [ AppComponent ]
})

export class AppModule {

  constructor(
    private router: Router
  ) {
    router.events.subscribe((event) => {

      if(event instanceof NavigationStart) {
        NProgress.start();
      }

      if(event instanceof NavigationEnd) {
        setTimeout(function(){
          NProgress.done();
        }, 200);
      }

    });
  }
}
