import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";

@Injectable()
export class EventService {

    private searchSource = new Subject<string>();
    search$ = this.searchSource.asObservable();

    private listViewSource = new Subject<boolean>();
    listView$ = this.listViewSource.asObservable();

    doSearch(term: string) {
        this.searchSource.next(term)
    }

    toggleView(isList: boolean) {
        this.listViewSource.next(isList)
    }

}