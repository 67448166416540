import { Injectable } from "@angular/core";

declare var $: any;
declare var jQuery: any;

@Injectable()
export class NotificacaoService {

    sucesso(mensagem:string, titulo: string = 'Sucesso!') {
        $(".alert-success").remove();
        $.notify({
            icon: 'font-icon font-icon-warning',
            title: `<strong>${titulo}</strong>`,
            message: mensagem
        },{
            type: 'success',
            placement: {
              from: "bottom",
              align: "center"
            }
        });
    }

    alerta(mensagem: string, titulo: string = 'Atenção!') {
        $(".alert-warning").remove();
        $.notify({
            icon: 'font-icon font-icon-warning',
            title: `<strong>${titulo}</strong>`,
            message: mensagem
        }, {
            type: 'warning',
            placement: {
              from: 'bottom',
              align: 'center'
            }
        });
    }

    erro(mensagem:string, titulo: string = 'Erro!') {
        $(".alert-danger").remove();
        $.notify({
            icon: 'font-icon font-icon-warning',
            title: `<strong>${titulo}</strong>`,
            message: mensagem
        },{
            type: 'danger',
            placement: {
              from: "bottom",
              align: "center"
            }
        });
    }

}